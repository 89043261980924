.main.menu {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.menu {
  padding: var(--air, .8em) 0;
}

.menu > * {
  margin: 0 var(--air, 1em);
}

.menu .MuiIconButton-root {
  /*color: var(--dark, black);
  /*background-color: var(--light, white);*/
  border: 1px solid var(--dark, darkgray);
  padding: 8px;
}

.menu .MuiIconButton-root svg {
  width: .9em;
  height: .9em;
}
