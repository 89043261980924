.uploadGroup {
  display: flex;
  align-items: center;

  .MuiTypography-root {
    font-size: 0.8rem;
  }
  .img-title {
    padding-bottom: 0.5rem;
  }
  .logo {
    display: flex;
    .img-group {
      display: flex;
    }
    .img-text {
      font-style: italic;
      align-self: flex-end;
    }
  }

}
