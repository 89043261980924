.card.actividad, .card.actividad_paralela {
  .actividad-wrap {
    flex-wrap: nowrap;
  }

  .sideControl {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .eurButton {
      padding: 2px;
    }

    .multiple-button {
      padding: 0;
    }
  }

  .contacto-menu {
    justify-content: flex-start;
  }

  .fecha {     
      // background: red;
      @media (min-width: 340px) {
        max-width: 6em;
    }
  }
  .WidgetContacto, .WidgetConcepto {
    max-width: 90%;
    input {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
  }
  .toggle-buttons div {
    display: flex;
    flex-direction: column;
    .MuiIconButton-root.comercial:not(.MuiIconButton-colorPrimary) {
      color: var(--warn);
    }
    .MuiIconButton-root.comercial.facturada:not(.MuiIconButton-colorPrimary) {
      color: var(--done);
    }
  }

  .valores .MuiInputLabel-root {
    width: 100%;
    text-align: right;
    transform-origin: right;
  }

  .descripcion input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dateForm {
  display: flex;
  flex-direction: row;
  padding: 0;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.167;
  height: 100%;
  align-self: center;
  border: none;

  .dateInput {
    width: 100%;
    border: none;
    background-color: white;
  }

  .dateInput::before {
    width: 100%;
    border: none;
  }
  .dateInput::after {
    width: 100%;
    border: none;
  }

  .dateInput input{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.167;
    font-size: 1.8rem;
    padding: 0;
    height: 100%;
  }
}

.dialog.actividad {
  .MuiDialog-paper {
    margin: var(--air);
  }
  .MuiDialog-paperFullWidth {
    width: calc(100% - var(--air) * 2);
  }
}

.actividadRow {
  display: flex;
  font-size: 0.8rem;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
  
  .valores {
    display: flex;
    .MuiGrid-item .MuiFormControl-root {       
      width: 70%;
    }
  }
  
}
