

fieldset.Acordeon {
  width: 100%;
  margin: var(--air, 1rem) 0;
  border: none;
  // padding: 0.5rem 0 0.5rem 0;
  

  legend.Acordeon-title {
    margin: 0;
    padding: 0;
    width: 100%;
    
  }

  legend button.MuiButton-root {
    // background-color: var(--dark, #333);
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: rgba(0, 0, 0, 0.87);
    justify-content: flex-end;
    .MuiButton-label {      
      font-size: .9em;
    }
  }

  div.Acordeon-content {
    margin: var(--air-half, .5rem);
    margin-top: var(--air);
  }
}
