.alias input{
  font-size: 1rem;
}
.acordeon-contacto { 
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none !important;
  &:after {
    box-shadow: none !important;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    margin: 0;
    min-height: 48px !important;
    .accordionControl {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
    }
    .MuiAccordionSummary-content {
      font-size: 1rem;
      margin: 0;
      justify-content: space-between;
      align-items: center;

      button {
        justify-content: flex-end;
        padding: 0;
        span {
          margin: 0;
          .MuiCollapse-wrapperInner {
            display: flex;
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .notas-inner-wrap {
    display: flex;
    flex-direction: row;
    .MuiTypography-root{
      font-size: 0.8rem;
    }
  }
}
