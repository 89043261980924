#root > main {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 5em;
}

#root > main > section.provisional {
  text-align: center;
}

#root > .notifications-component {
  top: 0;
}

#root > footer {
  display: none;
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: #fafafaef;
}

.workDays > div {
  padding-top: 1px;
}

.login{
  margin: auto;
  display: flex;
  justify-content: center;
  .login-container {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    max-width: 336px;

    .MuiAvatar-root {
      height: 65px;
      width: 65px;
    }
  }
}

.settings-container {

  .quick-settings {
    max-width: 850px;
    > button {
      color: black;
      width: 100%;
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      text-transform: none;

      .MuiCollapse-wrapperInner {
        display: flex;
      }
    }
  }  

  .settings {
    * {
      font-size: 0.8rem;
      margin-left: 0;
    }
    .MuiCollapse-wrapperInner > .MuiGrid-root {
      width: 95%;
    }
  }

  .settings-inner {
    justify-content: center;
    // max-width: 850px;
    .accordionControl {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px;
      button {
        text-transform: none;
        font-weight: 400;
        font-size: 1rem;
        color: black;
        margin-left: 10px;
        padding: 0;
      }
    }
  }

  > .MuiPaper-root {
    width: 100%;
    min-width: 340px;
    max-width: 100vw;
    overflow-y: auto;
    box-shadow: 4px 0px 10px -1px rgba(0, 0, 0, 0.2);
    background-color: white;
    color: black;
    font-size: 0.8rem;
    z-index: 10;
    overflow-y: overlay;
  }

  .quick-filters {
    .chip-filter {
      justify-content: center;
      margin-top: 20px;
      }
  }
}

@media (min-width: 768px) {
  .settings-container > .MuiPaper-root {
    width: 25vw;
    max-width: 450px;
  }
  .quick-settings {
    > button {
      .MuiButton-endIcon {
        margin-left: 2rem;
      }
    }
  }
}

.slide-popper > .MuiPaper-root{
  background-color: white;
  width: 300px;
  z-index: 2000;
  padding: 30px 20px 10px 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
              0px 1px 1px 0px rgba(0,0,0,0.14),
              0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;

  span {
      font-size: 0.8rem;
  }
}

.MuiDrawer-root {
  nav.drawer-menu {
    display: flex;
    flex-flow: column wrap;
    padding: var(--air);
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(100, 181, 246, 0.5);
  border: 0px none #f3e7e7;
  border-radius: 50px;

  &:hover {
    background: rgba(100, 181, 246, 0.8);
  }

  &:active {
    background: rgba(100, 181, 246, 0.8);
  }
}

::-webkit-scrollbar-track {
  background: rgba(190, 190, 190, 0.2);
  border: 0px none #ffffff;
  border-radius: 6px;
}


::-webkit-scrollbar-corner {
  background: transparent;
}

@media (min-width: 768px) {
  #root > main {
    padding-bottom: 8em;
  }

  ::-webkit-scrollbar-track {
    background: rgba(190, 190, 190, 0.5);
    border: 0px none #ffffff;
    border-radius: 6px;
  }
}
