body { margin: 0; background-color: var(--body-bg); }
html { scrollbar-width: thin; overflow-y: overlay;}

#root {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  /* justify-content: space-around;*/

  font-family: var(--main-font);
}

:root {
  --main-font: "Roboto", "Helvetica", "Arial", sans-serif;
  /**
   * Colores corporativos Grupo Marea
   */
  --marea-red: hsl(7, 57%, 46%);
  --marea-dark: hsl(197, 100%, 24%);
  --marea-light: hsl(207, 32%, 62%);

  /**
   * Spacing strategy, taken from my beloved baby @iaigz/wui
   */
  --air: .5rem;
  --air-full: var(--air);
  --air-half: calc(var(--air) / 2);
  --air-quad: calc(var(--air) / 4);
  --air-hack: calc(var(--air) * -1);
  --air-more: calc(var(--air) * 1.5);

  --body-bg: #fafafa;

  /**
   * Color palette, taken from material-ui defaults
   * see https://material-ui.com/es/customization/palette/
   * */
  --main: #2196f3;
  --main-dark: #1769aa;
  --main-light: #4dabf5;

  --alt1: #f50057;
  --alt1-dark: #ab003c;
  --alt1-light: #f73378;

  --fail: #f44336;
  --fail-dark: #d32f2f;
  --fail-light: #e57373;

  --warn: #ff9800;
  --warn-dark: #f57c00;
  --warn-light: #ffb74d;

  --info: #2196f3;
  --info-dark: #1976d2;
  --info-light: #64b5f6;

  --done: #4caf50;
  --done-dark: #388e3c;
  --done-light: #81c784;
}
