
.selector-dialog {
  --bg: var(--marea-dark);

  .MuiAvatar-root.avatar-opcion {
    background-color: var(--bg);
    font-size: 1rem;

    &.un-tipo {
      --bg: var(--warn, orange);
      &.un-subtipo { --bg: var(--pass, green); }
    }
    &.otro-tipo { --bg: var(--main); }
  }
}

.selector-dialog-field {
  &, input { cursor: pointer; }
}

.MuiList-root {
  margin-top: 5%;
}
