.dialogAjustes {
  .MuiDialogTitle-root {
    padding: 16px 24px 0px;
  }
  
  .MuiSubheader-root {
    line-height: 30px;
  }
  
  .MuiAvatarGroup-root {
    align-self: flex-start;
  }

  .empresa-activa {
    width: 25px;
    height: 25px;
    background-color: green;

    .MuiSvgIcon-root {
      font-size: 0.9rem;
    }
  }

  .MuiDivider-root {
    margin: 10px 0px;
  }
}

