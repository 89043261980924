:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media (prefers-reduced-motion: reduce), print {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  0%, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translateZ(0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translateZ(0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  0%, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scaleX(1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scaleX(1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  0%, to {
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  0%, to {
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  0% {
    transform: translateZ(0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate(-5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate(3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate(-3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate(2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate(-1deg);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  0%, 11.1%, to {
    transform: translateZ(0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  0% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(-1turn);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translateZ(0)rotateY(0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(0);
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateY(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateY(10deg);
  }

  80% {
    transform: perspective(400px)rotateY(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateY(-15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    opacity: 0;
    transform: rotate(-200deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    transform: rotate(-90deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate(80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate(-120deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate(120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

.rnc__notification-container--bottom-center, .rnc__notification-container--bottom-full, .rnc__notification-container--bottom-left, .rnc__notification-container--bottom-right, .rnc__notification-container--center, .rnc__notification-container--top-center, .rnc__notification-container--top-full, .rnc__notification-container--top-left, .rnc__notification-container--top-right {
  pointer-events: all;
  min-width: 325px;
  position: absolute;
}

.rnc__notification-container--bottom-center, .rnc__notification-container--center, .rnc__notification-container--top-center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  display: flex;
  left: calc(50% - 175px);
}

.rnc__notification-container--center {
  pointer-events: none;
  height: 100%;
  top: 20px;
}

.rnc__notification-container--bottom-full, .rnc__notification-container--top-full {
  width: 100%;
  min-width: 100%;
}

.rnc__notification-container--bottom-full {
  bottom: 0;
}

.rnc__util--flex-center {
  pointer-events: all;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 325px;
  display: flex;
}

.rnc__notification-container--top-center {
  top: 20px;
}

.rnc__notification-container--bottom-center {
  bottom: 20px;
}

.rnc__notification-container--top-left {
  top: 20px;
  left: 20px;
}

.rnc__notification-container--top-right {
  top: 20px;
  right: 20px;
}

.rnc__notification-container--bottom-left {
  bottom: 20px;
  left: 20px;
}

.rnc__notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}

.rnc__notification-container--mobile-bottom, .rnc__notification-container--mobile-top {
  pointer-events: all;
  position: absolute;
}

.rnc__notification-container--mobile-top {
  top: 20px;
  left: 20px;
  right: 20px;
}

.rnc__notification-container--mobile-bottom {
  margin-bottom: -15px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.rnc__notification-item--default {
  background-color: #007bff;
  border-left: 8px solid #0562c7;
}

.rnc__notification-item--default .rnc__notification-timer {
  background-color: #007bff;
}

.rnc__notification-item--default .rnc__notification-timer-filler {
  background-color: #fff;
}

.rnc__notification-item--default .rnc__notification-close-mark {
  background-color: #007bff;
}

.rnc__notification-item--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;
}

.rnc__notification-item--success .rnc__notification-timer {
  background-color: #28a745;
}

.rnc__notification-item--success .rnc__notification-timer-filler {
  background-color: #fff;
}

.rnc__notification-item--success .rnc__notification-close-mark {
  background-color: #28a745;
}

.rnc__notification-item--danger {
  background-color: #dc3545;
  border-left: 8px solid #bd1120;
}

.rnc__notification-item--danger .rnc__notification-timer {
  background-color: #dc3545;
}

.rnc__notification-item--danger .rnc__notification-timer-filler {
  background-color: #fff;
}

.rnc__notification-item--danger .rnc__notification-close-mark {
  background-color: #dc3545;
}

.rnc__notification-item--info {
  background-color: #17a2b8;
  border-left: 8px solid #138b9e;
}

.rnc__notification-item--info .rnc__notification-timer {
  background-color: #17a2b8;
}

.rnc__notification-item--info .rnc__notification-timer-filler {
  background-color: #fff;
}

.rnc__notification-item--info .rnc__notification-close-mark {
  background-color: #17a2b8;
}

.rnc__notification-item--warning {
  background-color: #eab000;
  border-left: 8px solid #ce9c09;
}

.rnc__notification-item--warning .rnc__notification-timer {
  background-color: #eab000;
}

.rnc__notification-item--warning .rnc__notification-timer-filler {
  background-color: #fff;
}

.rnc__notification-item--warning .rnc__notification-close-mark {
  background-color: #eab000;
}

.rnc__notification-item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;
}

.rnc__notification-item--awesome .rnc__notification-timer {
  background-color: #685dc3;
}

.rnc__notification-item--awesome .rnc__notification-timer-filler {
  background-color: #fff;
}

.rnc__notification-item--awesome .rnc__notification-close-mark {
  background-color: #685dc3;
}

@keyframes timer {
  0% {
    width: 100%;
  }

  to {
    width: 0;
  }
}

.rnc__base {
  pointer-events: none;
  z-index: 9000;
  width: 100%;
  height: 100%;
  position: fixed;
}

.rnc__notification-item {
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 15px;
  display: flex;
  position: relative;
  box-shadow: 1px 3px 4px #0003;
}

.rnc__notification-container--bottom-full .rnc__notification-item, .rnc__notification-container--top-full .rnc__notification-item {
  border-radius: 0;
  margin-bottom: 0;
}

.rnc__notification-container--bottom-full .rnc__notification, .rnc__notification-container--top-full .rnc__notification {
  width: 100% !important;
}

.rnc__notification-timer {
  width: 100%;
  margin-top: 10px;
}

.rnc__notification-timer, .rnc__notification-timer-filler {
  border-radius: 5px;
  height: 3px;
}

.rnc__notification-title {
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}

.rnc__notification-message {
  word-wrap: break-word;
  color: #fff;
  max-width: calc(100% - 15px);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 150%;
}

.rnc__notification-content {
  width: 100%;
  padding: 8px 15px;
  display: inline-block;
}

.rnc__notification-close-mark {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.rnc__notification-close-mark:after {
  color: #fff;
  content: "×";
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rnc__notification-container--mobile-bottom .notification, .rnc__notification-container--mobile-bottom .rnc__notification-item, .rnc__notification-container--mobile-top .notification, .rnc__notification-container--mobile-top .rnc__notification-item {
  width: 100%;
  max-width: 100%;
}

.rnc__notification-container--bottom-right .notification, .rnc__notification-container--top-right .notification {
  margin-left: auto;
}

.rnc__notification-container--bottom-left .notification, .rnc__notification-container--top-left .notification {
  margin-right: auto;
}

.rnc__notification-container--mobile-bottom .notification, .rnc__notification-container--mobile-top .notification {
  margin-left: auto;
  margin-right: auto;
}

fieldset.Acordeon {
  margin: var(--air, 1rem) 0;
  border: none;
  width: 100%;
}

fieldset.Acordeon legend.Acordeon-title {
  width: 100%;
  margin: 0;
  padding: 0;
}

fieldset.Acordeon legend button.MuiButton-root {
  color: #000000de;
  border: 1px solid #0000003b;
  justify-content: flex-end;
}

fieldset.Acordeon legend button.MuiButton-root .MuiButton-label {
  font-size: .9em;
}

fieldset.Acordeon div.Acordeon-content {
  margin: var(--air-half, .5rem);
  margin-top: var(--air);
}

.main.menu {
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.menu {
  padding: var(--air, .8em) 0;
}

.menu > * {
  margin: 0 var(--air, 1em);
}

.menu .MuiIconButton-root {
  border: 1px solid var(--dark, darkgray);
  padding: 8px;
}

.menu .MuiIconButton-root svg {
  width: .9em;
  height: .9em;
}

.quick-date {
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  display: flex;
}

.quick-date .MuiMonthPicker-root, .quick-date .MuiCalendarPicker-root, .quick-date .MuiYearPicker-root {
  height: 358px;
}

.quick-date .MuiTabPanel-root {
  min-height: 340px;
  padding: 0;
}

.quick-date .MuiTabPanel-root > div .PrivatePickersYear-root {
  flex-basis: 10%;
}

.quick-date .dias {
  background-color: #0000;
}

.quick-date .dias:hover, .quick-date .dias:focus {
  background-color: #efefef;
}

.quick-date .selected {
  background-color: #42a5f599;
}

.quick-date .selected > .dias {
  color: #fff;
}

.quick-date .selected > .dias:hover, .quick-date .selected > .dias:focus {
  background-color: #0000;
  border: .2px solid #0006;
}

.quick-date .selected.dia-central {
  border-radius: 0;
}

.quick-date .selected.dia-central > .dias {
  color: #000000de;
}

.quick-date .selected.dia-izquierdo {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.quick-date .selected.dia-izquierdo > .dias {
  background-color: #1976d2;
  border-radius: 50%;
}

.quick-date .selected.dia-derecho {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.quick-date .selected.dia-derecho > .dias, .quick-date .selected.dia-unico {
  background-color: #1976d2;
  border-radius: 50%;
}

.quick-date .PrivatePickersSlideTransition-root .css-mvmu1r :last-child {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.quick-date .PrivatePickersSlideTransition-root .css-mvmu1r :first-child {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.selector-dialog {
  --bg: var(--marea-dark);
}

.selector-dialog .MuiAvatar-root.avatar-opcion {
  background-color: var(--bg);
  font-size: 1rem;
}

.selector-dialog .MuiAvatar-root.avatar-opcion.un-tipo {
  --bg: var(--warn, orange);
}

.selector-dialog .MuiAvatar-root.avatar-opcion.un-tipo.un-subtipo {
  --bg: var(--pass, green);
}

.selector-dialog .MuiAvatar-root.avatar-opcion.otro-tipo {
  --bg: var(--main);
}

.selector-dialog-field, .selector-dialog-field input {
  cursor: pointer;
}

.MuiList-root {
  margin-top: 5%;
}

.MuiFormControl-root label.MuiFormLabel-root {
  white-space: nowrap;
}

.MuiInputAdornment-root .MuiIconButton-root .MuiSvgIcon-root {
  width: .7em;
  height: .7em;
}

figure.logo {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

figure.logo .imagotipo {
  min-width: 120px;
  max-width: 100%;
  max-height: 120px;
}

.documento {
  padding: var(--air) 1rem;
  margin: var(--air);
}

.documento .titulo {
  margin: var(--air);
}

.documento figure.datos.cliente {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0;
  display: flex;
}

.documento figure.datos.cliente:before {
  box-sizing: border-box;
  width: 100%;
}

.documento .datos[data-title]:before {
  content: attr(data-title);
  text-transform: uppercase;
  margin-bottom: var(--air-half);
  padding-left: calc(var(--air)  + 1px);
  font-size: .9em;
  font-weight: lighter;
  display: block;
}

.documento .datos.cliente table {
  text-align: left;
  border: .5px solid #c9bfbf;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.documento .datos.cliente table td small {
  color: var(--disabled, lightgray);
  font-style: italic;
}

.documento p {
  text-align: justify;
  margin-bottom: 15px;
}

.documento h6 {
  text-align: center;
}

.documento .firma {
  margin-bottom: 6rem;
}

.documento footer {
  font-weight: lighter;
}

.documento footer address {
  text-align: center;
  font-size: .8em;
}

.dialogAjustes .MuiDialogTitle-root {
  padding: 16px 24px 0;
}

.dialogAjustes .MuiSubheader-root {
  line-height: 30px;
}

.dialogAjustes .MuiAvatarGroup-root {
  align-self: flex-start;
}

.dialogAjustes .empresa-activa {
  background-color: green;
  width: 25px;
  height: 25px;
}

.dialogAjustes .empresa-activa .MuiSvgIcon-root {
  font-size: .9rem;
}

.dialogAjustes .MuiDivider-root {
  margin: 10px 0;
}

#root > header.MuiAppBar-root {
  background-color: #fafafaef;
}

#root > header.MuiAppBar-root .MuiBottomNavigation-root {
  background-color: var(--main-dark);
}

#root > header.MuiAppBar-root .MuiBottomNavigation-root .MuiBottomNavigationAction-root {
  color: #fff;
}

#root > header.MuiAppBar-root .MuiBottomNavigation-root .MuiBottomNavigationAction-root.Mui-selected {
  color: var(--alt1-light);
}

#root > header.MuiAppBar-root .MuiBottomNavigation-root .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label {
  color: #fff;
  max-height: 15px;
  overflow: hidden;
}

#root > header.MuiAppBar-root .MuiBottomNavigation-root .MuiBottomNavigationAction-root .MuiBottomNavigationAction-label {
  max-height: 15px;
  overflow: hidden;
}

#root > header.MuiAppBar-root .logo {
  max-width: 24px;
  max-height: 24px;
}

#root > header.MuiAppBar-root .MuiToolbar-root {
  box-sizing: border-box;
  padding: 0 var(--air);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 90vh;
  display: flex;
}

#root > header.MuiAppBar-root .MuiToolbar-root .MuiInputAdornment-root .MuiIconButton-root {
  color: var(--marea-dark);
  padding: 6px;
}

#root > header.MuiAppBar-root .MuiToolbar-root > .MuiChip-root {
  margin-right: 12px;
}

#root > header.MuiAppBar-root .MuiToolbar-root .MuiChip-outlined {
  border: none;
  justify-content: flex-start;
  width: 100%;
}

#root > header.MuiAppBar-root .MuiToolbar-root .MuiInputBase-root:before {
  border-bottom-width: 1px;
}

#root > header.MuiAppBar-root .MuiToolbar-root .MuiInputBase-root:not(:focus):not(:hover):before {
  content: unset;
}

#root > header.MuiAppBar-root .MuiToolbar-root .MuiInputBase-root .MuiInputBase-input {
  padding-bottom: 3px;
}

#root > header.MuiAppBar-root .MuiToolbar-root .searchPopper {
  z-index: 10 !important;
}

#root > header.MuiAppBar-root .MuiToolbar-root .searchPopper > .options-container {
  padding: var(--air);
  flex-direction: column;
  max-height: 80vh;
  display: flex;
  overflow-y: auto;
}

#root > header.MuiAppBar-root .MuiToolbar-root .searchPopper > .options-container > :not(:first-child) {
  margin-top: var(--air);
}

#root > header.MuiAppBar-root .searchBar {
  align-items: center;
  width: 100%;
  max-width: 550px;
  display: flex;
}

#root > header.MuiAppBar-root .searchBar .MuiInputBase-inputTypeSearch {
  margin-left: 1rem;
}

#root > header.MuiAppBar-root .chipList {
  align-items: center;
  display: flex;
}

#root > header.MuiAppBar-root .chipList .MuiChip-label:first-letter {
  text-transform: uppercase;
}

.newCard {
  padding: var(--air) var(--air) 0 var(--air);
  z-index: 3;
  box-shadow: none;
  cursor: pointer;
  width: 98%;
  max-width: 850px;
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
}

.newCard .newCardContent {
  color: #00000042;
  padding: var(--air);
  align-items: center;
  display: flex;
}

.newCard .newCardContent span {
  margin-left: 10px;
}

section.card-list {
  box-sizing: border-box;
  padding: var(--air);
  margin-top: var(--air);
  width: 100%;
}

.group-separator-container {
  justify-content: center;
  width: 100%;
}

.group-separator-container .group-separator {
  width: 850px;
  padding-top: 1rem;
  font-size: .8rem;
  display: flex;
}

.WidgetContacto .MuiInputBase-root input, .WidgetConcepto .MuiInputBase-root input, .fecha .MuiInputBase-root input, .descripcion .MuiInputBase-root input {
  padding-top: 3px;
}

.WidgetContacto label.MuiInputLabel-root[data-shrink="false"], .WidgetConcepto label.MuiInputLabel-root[data-shrink="false"], .fecha label.MuiInputLabel-root[data-shrink="false"], .descripcion label.MuiInputLabel-root[data-shrink="false"] {
  transform: translate(0, 21px)scale(1);
}

.fecha .MuiInputBase-root input, .WidgetContacto .MuiInputBase-root input {
  font-weight: 500;
}

.dialog-contacto-container .dialog-title {
  align-items: center;
  padding-bottom: 0;
  display: flex;
}

.dialog-contacto-container .dialog-title .MuiAvatar-root {
  margin-right: 1rem;
}

.dialog-contacto-container .DialogContacto {
  min-width: 320px;
  padding: 10px !important;
}

.dialog-contacto-container .DialogContacto .MuiTypography-root {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.dialog-contacto-container .DialogContacto .MuiDivider-root {
  margin-bottom: 1rem;
}

.dialog-contacto-container .DialogContacto .MuiList-root {
  margin-top: 0 !important;
}

.dialog-contacto-container .DialogContacto .contact-group {
  justify-content: space-evenly;
  padding-bottom: 16px;
}

.Ayuntamiento div {
  font-size: .8rem !important;
}

.card:hover {
  box-shadow: 0 2px 4px -1px;
}

.createCard.selected {
  box-shadow: 0 5px 5px -3px;
}

.card {
  grid-column: 2;
  box-shadow: 0 2px 1px -1px;
}

.card menu.card-menu {
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.card menu.card-menu .MuiIconButton-root svg {
  width: .82em;
  height: .82em;
}

.card menu.card-menu .info-contacto {
  text-transform: none;
  color: #000000de;
  cursor: pointer;
  text-align: start;
  z-index: 2;
  background-color: #00000014;
  border-radius: 20px;
  justify-content: flex-start;
  max-width: 100%;
  font-size: .7rem;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
}

.card menu.card-menu .info-contacto span {
  padding-right: 5px;
}

.card menu.card-menu .info-contacto:hover {
  background-color: #00000026;
}

.card form {
  margin-top: var(--air-half);
}

.card form .Acordeon:last-child {
  margin-bottom: 0;
}

.card form .Acordeon legend .MuiButton-root, .card .MuiInputBase-root, .card .MuiFormLabel-root, .card .MuiOutlinedInput-root, .card .MuiOutlinedInput-root fieldset legend, .card .MuiInputAdornment-root .MuiTypography-root {
  font-size: .8rem;
}

.card .MuiFormControl-root .MuiInputBase-root.MuiInput-underline:not(:hover):before {
  content: unset;
}

.card .MuiFormControl-root .MuiInputBase-root.MuiInput-underline input, .card .MuiFormControl-root .MuiInputBase-root.MuiInput-underline .MuiSelect-root {
  padding-bottom: 4px;
}

.card .MuiFormControl-root .MuiInputBase-root.MuiInput-underline .MuiSelect-root {
  padding-left: 4px;
}

.card form .MuiInputBase-inputMultiline {
  resize: vertical;
}

.card .tabContent {
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  column-gap: 5%;
  width: 100%;
  margin: 0;
  display: grid;
}

.card .tabContent .MuiGrid-item {
  padding: 0;
}

.card .tabContent .MuiGrid-item .MuiFormControl-root {
  width: 70%;
}

article.card, article.card + div, .MuiDialogContent-root.card.actividad.selected {
  box-sizing: border-box;
  margin: var(--air) auto;
  padding: var(--air);
  width: 100%;
  max-width: 850px;
  position: relative;
}

article.card:focus, article.card + div:focus, .MuiDialogContent-root.card.actividad.selected:focus {
  outline: 0;
}

article.card .empresa-activa, article.card + div .empresa-activa, .MuiDialogContent-root.card.actividad.selected .empresa-activa {
  right: var(--air-half);
  top: calc(var(--air-more) * -1);
  position: absolute;
}

article.card .MuiAvatarGroup-root, article.card + div .MuiAvatarGroup-root, .MuiDialogContent-root.card.actividad.selected .MuiAvatarGroup-root {
  right: var(--air-half);
  top: calc(-1 * var(--air-more));
  flex-direction: column;
  align-items: flex-end;
}

article.card .MuiAvatarGroup-root button, article.card + div .MuiAvatarGroup-root button, .MuiDialogContent-root.card.actividad.selected .MuiAvatarGroup-root button {
  justify-content: flex-end;
  width: 35px;
  padding: 0;
}

article.card .multiple, article.card + div .multiple, .MuiDialogContent-root.card.actividad.selected .multiple {
  flex-direction: row;
  justify-content: right;
}

article.card .multiple :not(.MuiSvgIcon-root), article.card + div .multiple :not(.MuiSvgIcon-root), .MuiDialogContent-root.card.actividad.selected .multiple :not(.MuiSvgIcon-root) {
  margin-left: -25px !important;
}

article.card .sm-avatar, article.card + div .sm-avatar, .MuiDialogContent-root.card.actividad.selected .sm-avatar {
  z-index: 1;
  --bg: var(--dark, gray);
  background-color: var(--bg);
  width: 30px;
  height: 30px;
}

article.card .sm-avatar.estado.abierta, article.card + div .sm-avatar.estado.abierta, .MuiDialogContent-root.card.actividad.selected .sm-avatar.estado.abierta {
  --bg: var(--info, #2196f3);
}

article.card .sm-avatar.estado.cerrada, article.card + div .sm-avatar.estado.cerrada, .MuiDialogContent-root.card.actividad.selected .sm-avatar.estado.cerrada {
  --bg: var(--done, orange);
}

article.card .sm-avatar.estado.anulada, article.card + div .sm-avatar.estado.anulada, .MuiDialogContent-root.card.actividad.selected .sm-avatar.estado.anulada {
  --bg: var(--fail, orange);
}

article.card .sm-avatar.estado.atrasada, article.card + div .sm-avatar.estado.atrasada, .MuiDialogContent-root.card.actividad.selected .sm-avatar.estado.atrasada {
  --bg: var(--warn);
}

article.card .sm-avatar.estado.oculta, article.card + div .sm-avatar.estado.oculta, .MuiDialogContent-root.card.actividad.selected .sm-avatar.estado.oculta {
  display: none;
}

article.card .sm-avatar.comercial.pendiente, article.card + div .sm-avatar.comercial.pendiente, .MuiDialogContent-root.card.actividad.selected .sm-avatar.comercial.pendiente {
  --bg: var(--dark, gray);
}

article.card .sm-avatar.comercial.emitida, article.card + div .sm-avatar.comercial.emitida, .MuiDialogContent-root.card.actividad.selected .sm-avatar.comercial.emitida {
  --bg: var(--warn, orange);
}

article.card .sm-avatar.comercial.facturada, article.card + div .sm-avatar.comercial.facturada, .MuiDialogContent-root.card.actividad.selected .sm-avatar.comercial.facturada {
  --bg: var(--done, green);
}

article.card .sm-avatar.comercial.atrasada, article.card + div .sm-avatar.comercial.atrasada, .MuiDialogContent-root.card.actividad.selected .sm-avatar.comercial.atrasada {
  --bg: var(--fail, red);
}

article.card:not(.selected):after, article.card + div:not(.selected):after, .MuiDialogContent-root.card.actividad.selected:not(.selected):after {
  content: " ";
  background-color: #ffffff1a;
  display: block;
  position: absolute;
  inset: 0;
}

article.card:not(.selected):hover:after, article.card + div:not(.selected):hover:after, .MuiDialogContent-root.card.actividad.selected:not(.selected):hover:after {
  background-color: #0000;
  transition: all .5s;
}

article.card:not(.selected), article.card:focus:not(.selected), article.card + div:not(.selected), article.card + div:focus:not(.selected), .MuiDialogContent-root.card.actividad.selected:not(.selected), .MuiDialogContent-root.card.actividad.selected:focus:not(.selected) {
  cursor: pointer;
}

@media (width >= 768px) {
  .newCard {
    padding: 0;
  }
}

article.card.todo .MuiGrid-container.actividad-comercial {
  margin-top: calc(var(--air-more) * 4);
  margin-left: -4px;
}

article.card.todo menu.actividades {
  text-align: right;
}

.WidgetContacto-dialog .MuiPaper-root, .WidgetConcepto-dialog .MuiPaper-root {
  min-width: 320px;
  height: 650px;
}

.WidgetContacto-dialog .MuiDialogContent-root, .WidgetConcepto-dialog .MuiDialogContent-root {
  min-width: fit-content;
  padding: 10px;
  overflow-y: hidden;
}

.WidgetContacto-dialog .MuiListItem-gutters, .WidgetConcepto-dialog .MuiListItem-gutters {
  padding-left: 8px;
  padding-right: 8px;
}

.WidgetContacto-dialog .MuiList-root, .WidgetConcepto-dialog .MuiList-root {
  min-height: 90%;
}

.WidgetContacto-dialog .listFooter, .WidgetConcepto-dialog .listFooter {
  margin-top: 10px;
}

.card.actividad .actividad-wrap, .card.actividad_paralela .actividad-wrap {
  flex-wrap: nowrap;
}

.card.actividad .sideControl, .card.actividad_paralela .sideControl {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.card.actividad .sideControl .eurButton, .card.actividad_paralela .sideControl .eurButton {
  padding: 2px;
}

.card.actividad .sideControl .multiple-button, .card.actividad_paralela .sideControl .multiple-button {
  padding: 0;
}

.card.actividad .contacto-menu, .card.actividad_paralela .contacto-menu {
  justify-content: flex-start;
}

@media (width >= 340px) {
  .card.actividad .fecha, .card.actividad_paralela .fecha {
    max-width: 6em;
  }
}

.card.actividad .WidgetContacto, .card.actividad .WidgetConcepto, .card.actividad_paralela .WidgetContacto, .card.actividad_paralela .WidgetConcepto {
  max-width: 90%;
}

.card.actividad .WidgetContacto input, .card.actividad .WidgetConcepto input, .card.actividad_paralela .WidgetContacto input, .card.actividad_paralela .WidgetConcepto input {
  text-overflow: ellipsis;
  overflow: hidden;
}

.card.actividad .toggle-buttons div, .card.actividad_paralela .toggle-buttons div {
  flex-direction: column;
  display: flex;
}

.card.actividad .toggle-buttons div .MuiIconButton-root.comercial:not(.MuiIconButton-colorPrimary), .card.actividad_paralela .toggle-buttons div .MuiIconButton-root.comercial:not(.MuiIconButton-colorPrimary) {
  color: var(--warn);
}

.card.actividad .toggle-buttons div .MuiIconButton-root.comercial.facturada:not(.MuiIconButton-colorPrimary), .card.actividad_paralela .toggle-buttons div .MuiIconButton-root.comercial.facturada:not(.MuiIconButton-colorPrimary) {
  color: var(--done);
}

.card.actividad .valores .MuiInputLabel-root, .card.actividad_paralela .valores .MuiInputLabel-root {
  text-align: right;
  transform-origin: 100%;
  width: 100%;
}

.card.actividad .descripcion input, .card.actividad_paralela .descripcion input {
  text-overflow: ellipsis;
  overflow: hidden;
}

.dateForm {
  border: none;
  flex-direction: row;
  align-self: center;
  height: 100%;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.167;
  display: flex;
}

.dateForm .dateInput {
  background-color: #fff;
  border: none;
  width: 100%;
}

.dateForm .dateInput:before, .dateForm .dateInput:after {
  border: none;
  width: 100%;
}

.dateForm .dateInput input {
  text-align: center;
  height: 100%;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  line-height: 1.167;
}

.dialog.actividad .MuiDialog-paper {
  margin: var(--air);
}

.dialog.actividad .MuiDialog-paperFullWidth {
  width: calc(100% - var(--air) * 2);
}

.actividadRow {
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  font-size: .8rem;
  display: flex;
}

.actividadRow .valores {
  display: flex;
}

.actividadRow .valores .MuiGrid-item .MuiFormControl-root {
  width: 70%;
}

section.agenda {
  box-sizing: border-box;
  padding: var(--air);
  --bg: white;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  min-height: 80vh;
  max-height: 85vh;
  position: absolute;
  overflow: hidden;
}

section.agenda .react-swipeable-view-container {
  height: 100%;
}

section.agenda .wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

section.agenda .loadingBackground {
  z-index: 5;
  position: absolute;
}

section.agenda .loading {
  z-index: 5;
  background-color: #fff9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

section.agenda .loading span {
  position: absolute;
  bottom: 50%;
  left: 50%;
}

section.agenda .fc {
  width: 100%;
  height: 100%;
}

section.agenda .fc table {
  font-size: .7rem;
}

section.agenda .fc table th.fc-timegrid-axis {
  border: none;
}

section.agenda .fc table colgroup col {
  width: 34px !important;
}

section.agenda .fc table .fc-timegrid-slots table {
  margin-top: 10px;
}

section.agenda .fc .fc-highlight {
  z-index: 0;
  background: none;
}

section.agenda .fc .fc-scrollgrid-liquid {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px #0003, 0 2px 1px #00000024;
}

section.agenda .fc .fc-scrollgrid-liquid th {
  border-right: none;
}

section.agenda .fc .fc-scrollgrid-liquid thead .fc-scroller {
  overflow: hidden !important;
}

section.agenda .fc .fc-mes-view .fc-scrollgrid-liquid {
  border-left: 1px solid var(--fc-border-color, #ddd);
}

section.agenda .fc .fc-timegrid {
  overflow-y: hidden;
}

section.agenda .fc .fc-timegrid-axis-cushion {
  text-align: right;
  max-width: 33px;
  padding: 0 2px 0 0;
}

section.agenda .fc .fc-timegrid-slot-label-cushion {
  padding: 0 2px 0 0;
}

section.agenda .fc .fc-timegrid-slot {
  height: 3rem !important;
}

section.agenda .fc .fc-timegrid-event:hover, section.agenda .fc .fc-daygrid-event:hover {
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px;
}

section.agenda .fc .fc-col-header-cell {
  text-transform: capitalize;
  font-weight: normal;
  border-left: none !important;
  border-right: none !important;
}

section.agenda .fc .fc-theme-standard td, section.agenda .fc .fc-theme-standard th {
  border: none !important;
}

section.agenda .fc .fc-day-today:not(.fc-col-header-cell) {
  background-color: #4dabf526;
}

section.agenda .fc a.fc-event, section.agenda .fc a.fc-h-event {
  background-color: #fff;
  border: 1px solid #0003;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

section.agenda .fc a.fc-event :hover, section.agenda .fc a.fc-h-event :hover {
  box-shadow: .3s cubic-bezier(.4, 0, .2, 1) 0s;
}

section.agenda .fc a.fc-event .fc-event-main, section.agenda .fc a.fc-h-event .fc-event-main {
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

section.agenda .fc .fc-daygrid-day {
  height: 20%;
  max-height: 20%;
}

section.agenda .fc .fc-daygrid-day-events {
  margin-bottom: 2rem;
}

section.agenda .fc .eventContainer {
  color: #000000de;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-left: 2px;
  display: flex;
}

section.agenda .fc .eventContainer .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

section.agenda .fc .eventContainer .dot-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

section.agenda .fc .eventContainer .dot-wrap .status-dot {
  border-radius: 1em;
  margin-top: -6px;
  box-shadow: inset 1px 1px 1px 2px #fff;
}

section.agenda .fc .eventContainer .dot-wrap .status-dot.abierta {
  color: var(--info);
}

section.agenda .fc .eventContainer .dot-wrap .status-dot.cerrada {
  color: var(--done);
}

section.agenda .fc .eventContainer .dot-wrap .status-dot.anulada {
  color: var(--fail);
}

section.agenda .fc .eventContainer .dot-wrap .status-dot.atrasada {
  color: var(--warn);
}

section.agenda .fc .eventContainer .dot-wrap .status-dot.oculta {
  display: none;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root {
  flex-direction: column-reverse;
  align-items: flex-end;
  width: 100%;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button {
  padding: 0;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button > .MuiAvatar-root, section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple > .MuiAvatar-root {
  --bg: var(--dark, gray);
  background-color: var(--bg);
  width: 1.1rem;
  height: 1.1rem;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button > .MuiAvatar-root.estado.abierta, section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple > .MuiAvatar-root.estado.abierta {
  --bg: var(--info, #2196f3);
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button > .MuiAvatar-root.estado.cerrada, section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple > .MuiAvatar-root.estado.cerrada {
  --bg: var(--done, orange);
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button > .MuiAvatar-root.estado.anulada, section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple > .MuiAvatar-root.estado.anulada {
  --bg: var(--fail, orange);
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button > .MuiAvatar-root.estado.atrasada, section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple > .MuiAvatar-root.estado.atrasada {
  --bg: var(--warn);
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root button > .MuiAvatar-root.estado.oculta, section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple > .MuiAvatar-root.estado.oculta {
  display: none;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple {
  flex-direction: row;
  justify-content: right;
  display: flex;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple :not(.MuiSvgIcon-root) {
  margin-left: -15px !important;
}

section.agenda .fc .eventContainer .MuiAvatarGroup-root.multiple .MuiAvatar-root {
  justify-content: space-around;
  width: 1.1rem;
  height: 1.1rem;
}

section.agenda .fc .eventContainer:hover > .MuiAvatarGroup-root > .MuiAvatar-root {
  color: var(--dark, gray);
  background-color: var(--light, white);
}

section.agenda .fc .eventContainer .eventText {
  text-align: start;
  flex-direction: column;
  grid-column: 1;
  justify-content: center;
  display: flex;
}

section.agenda .fc .eventContainer .MuiSvgIcon-root {
  font-size: .8rem;
}

section.agenda .fc .fc-mes-view .fc-timegrid-slot, section.agenda .fc .fc-semana-view .fc-timegrid-slot, section.agenda .fc .fc-tresDias-view .fc-timegrid-slot {
  height: 2rem !important;
}

section.agenda .fc .fc-mes-view .fc-timegrid-slot-label, section.agenda .fc .fc-mes-view .fc-timegrid-slot-label-frame, section.agenda .fc .fc-semana-view .fc-timegrid-slot-label, section.agenda .fc .fc-semana-view .fc-timegrid-slot-label-frame, section.agenda .fc .fc-tresDias-view .fc-timegrid-slot-label, section.agenda .fc .fc-tresDias-view .fc-timegrid-slot-label-frame {
  box-shadow: none;
  border: none;
  margin-top: -24px;
}

section.agenda .fc .fc-mes-view .eventContainer .status-avatar-wrap, section.agenda .fc .fc-semana-view .eventContainer .status-avatar-wrap, section.agenda .fc .fc-tresDias-view .eventContainer .status-avatar-wrap {
  display: none;
}

section.agenda .fc .fc-tresDias-view .eventContainer {
  font-size: .7rem;
}

section.agenda .fc .fc-dia-view .eventContainer {
  font-size: .8rem;
}

section.agenda .fc .fc-dia-view .eventContainer .dot-wrap {
  display: none;
}

@media (width >= 768px) {
  section.agenda .fc .fc-mes-view .eventContainer, section.agenda .fc .fc-semana-view .eventContainer, section.agenda .fc .fc-tresDias-view .eventContainer {
    justify-content: left;
  }

  section.agenda .fc .fc-mes-view .eventContainer .eventText, section.agenda .fc .fc-semana-view .eventContainer .eventText, section.agenda .fc .fc-tresDias-view .eventContainer .eventText {
    margin-left: 5px;
  }

  section.agenda .fc .fc-mes-view .eventContainer .dot-wrap, section.agenda .fc .fc-semana-view .eventContainer .dot-wrap, section.agenda .fc .fc-tresDias-view .eventContainer .dot-wrap {
    margin-left: 2px;
  }

  section.agenda .fc .fc-mes-view .fc-timegrid-slot-label, section.agenda .fc .fc-mes-view .fc-timegrid-slot-label-frame, section.agenda .fc .fc-semana-view .fc-timegrid-slot-label, section.agenda .fc .fc-semana-view .fc-timegrid-slot-label-frame, section.agenda .fc .fc-tresDias-view .fc-timegrid-slot-label, section.agenda .fc .fc-tresDias-view .fc-timegrid-slot-label-frame {
    margin-top: -27px;
  }
}

section.agenda .sideArrow {
  z-index: 5;
  border-radius: 0;
  padding: 0;
  display: inline-flex;
}

@media (width >= 768px) {
  section.agenda .sideArrow {
    display: inline-flex;
  }
}

.actividadAgenda {
  margin: 0 !important;
}

.alias input {
  font-size: 1rem;
}

.acordeon-contacto {
  border-top: 1px solid #0000001f;
  box-shadow: none !important;
}

.acordeon-contacto:after {
  box-shadow: none !important;
}

.acordeon-contacto .MuiAccordionSummary-root {
  margin: 0;
  padding: 0;
  min-height: 48px !important;
}

.acordeon-contacto .MuiAccordionSummary-root .accordionControl {
  flex-flow: row;
  display: flex;
}

.acordeon-contacto .MuiAccordionSummary-root .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 1rem;
}

.acordeon-contacto .MuiAccordionSummary-root .MuiAccordionSummary-content button {
  justify-content: flex-end;
  padding: 0;
}

.acordeon-contacto .MuiAccordionSummary-root .MuiAccordionSummary-content button span {
  margin: 0;
}

.acordeon-contacto .MuiAccordionSummary-root .MuiAccordionSummary-content button span .MuiCollapse-wrapperInner {
  color: #0000008a;
  display: flex;
}

.acordeon-contacto .MuiAccordionDetails-root {
  padding: 0;
}

.acordeon-contacto .notas-inner-wrap {
  flex-direction: row;
  display: flex;
}

.acordeon-contacto .notas-inner-wrap .MuiTypography-root {
  font-size: .8rem;
}

.bgText {
  z-index: 0;
  text-align: center;
  color: #fd46141a;
  margin: 0;
  font-size: 3.5rem;
  position: absolute;
  top: 8rem;
  right: 2rem;
  transform: rotate(320deg);
}

.factura, .borrador {
  box-sizing: border-box;
  --ancho-importe: 7rem;
  --bordr: 1px solid var(--text-weak, lightgray);
  --bg: var(--dark, gray);
  width: 100%;
  margin: 0;
}

.factura .factura-wrap, .borrador .factura-wrap {
  align-self: "center";
}

.factura .facturaClosed .MuiCollapse-wrapperInner, .borrador .facturaClosed .MuiCollapse-wrapperInner {
  flex-wrap: wrap;
  font-size: .8rem;
  display: flex;
}

.factura .facturaClosed .MuiCollapse-wrapperInner .datos-closed, .borrador .facturaClosed .MuiCollapse-wrapperInner .datos-closed {
  text-overflow: ellipsis;
  flex-wrap: wrap;
  display: flex;
  overflow: hidden;
}

.factura .facturaClosed .MuiCollapse-wrapperInner .datos-closed .label, .borrador .facturaClosed .MuiCollapse-wrapperInner .datos-closed .label {
  color: #0009;
  margin: .5rem 0;
  font-size: .6rem;
  font-weight: 400;
}

.factura .facturaClosed .MuiCollapse-wrapperInner .datos-closed .valor, .borrador .facturaClosed .MuiCollapse-wrapperInner .datos-closed .valor {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.factura .eurContainer, .borrador .eurContainer {
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.factura .eurContainer .euroButton, .borrador .eurContainer .euroButton {
  cursor: default;
  text-transform: none;
  color: #000000de;
  border-radius: 16px;
  min-width: fit-content;
  padding: 0;
  font-size: .7rem;
  font-weight: 400;
}

.factura .eurContainer .euroButton .MuiAvatar-root, .borrador .eurContainer .euroButton .MuiAvatar-root {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  font-size: 1.25rem;
}

.factura .menuContainer .MuiCollapse-wrapperInner, .borrador .menuContainer .MuiCollapse-wrapperInner {
  align-items: center;
  display: flex;
}

.factura .MuiAvatar-root, .borrador .MuiAvatar-root {
  background-color: var(--bg);
}

.factura .MuiAvatar-root.estado.abierta, .borrador .MuiAvatar-root.estado.abierta {
  --bg: var(--info, #2196f3);
}

.factura .MuiAvatar-root.estado.cerrada, .borrador .MuiAvatar-root.estado.cerrada {
  --bg: var(--done, orange);
}

.factura .MuiAvatar-root.estado.anulada, .borrador .MuiAvatar-root.estado.anulada {
  --bg: var(--fail, orange);
}

.factura .MuiAvatar-root.comercial.pendiente, .borrador .MuiAvatar-root.comercial.pendiente {
  display: none;
}

.factura .MuiAvatar-root.comercial.emitida, .borrador .MuiAvatar-root.comercial.emitida {
  --bg: var(--warn, orange);
}

.factura .MuiAvatar-root.comercial.facturada, .borrador .MuiAvatar-root.comercial.facturada {
  --bg: var(--done, green);
}

.factura .MuiAvatar-root.comercial.atrasada, .borrador .MuiAvatar-root.comercial.atrasada {
  --bg: var(--fail, red);
}

.factura table, .borrador table {
  border-collapse: collapse;
  box-sizing: border-box;
  border: var(--bordr);
  width: 100%;
  font-size: .8rem;
}

.factura table tr > *, .borrador table tr > * {
  text-align: center;
  padding: var(--air-half) var(--air-full);
}

.factura table th, .borrador table th {
  box-sizing: border-box;
  font-weight: lighter;
}

.factura table td, .borrador table td {
  font-weight: normal;
}

.factura .header, .borrador .header {
  margin-top: var(--air);
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.factura .header figure.logo, .borrador .header figure.logo {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
}

.factura .header figure.logo .imagotipo, .borrador .header figure.logo .imagotipo {
  min-width: 120px;
  max-width: 100%;
  max-height: 220px;
}

.factura .header figure.datos.factura, .factura .header figure.datos.borrador, .borrador .header figure.datos.factura, .borrador .header figure.datos.borrador {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.factura .header figure.datos.factura:before, .factura .header figure.datos.borrador:before, .borrador .header figure.datos.factura:before, .borrador .header figure.datos.borrador:before {
  box-sizing: border-box;
  width: 100%;
}

.factura .header td, .borrador .header td {
  width: calc(var(--ancho-importe) * 2);
}

.factura .header figure.datos.empresa, .borrador .header figure.datos.empresa {
  font-size: .7rem;
}

.factura .header figure.datos.empresa .MuiGrid-item, .borrador .header figure.datos.empresa .MuiGrid-item {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.factura .datos[data-title]:before, .borrador .datos[data-title]:before {
  content: attr(data-title);
  text-transform: uppercase;
  margin-bottom: var(--air-half);
  padding-left: calc(var(--air)  + 1px);
  font-size: .9em;
  font-weight: lighter;
  display: block;
}

.factura .datos.factura th, .factura .datos.factura td, .factura .datos.borrador th, .factura .datos.borrador td, .borrador .datos.factura th, .borrador .datos.factura td, .borrador .datos.borrador th, .borrador .datos.borrador td {
  border: var(--bordr);
  text-align: left;
}

.factura .datos.factura td small, .factura .datos.borrador td small, .borrador .datos.factura td small, .borrador .datos.borrador td small {
  color: var(--disabled, lightgray);
  font-style: italic;
}

.factura .datos.cliente, .borrador .datos.cliente {
  margin: 1rem 0 0;
}

.factura .datos.cliente > .MuiGrid-container, .borrador .datos.cliente > .MuiGrid-container {
  padding: 0 var(--air-quad);
  border: var(--bordr);
  font-size: .8rem;
}

.factura .datos.cliente > .MuiGrid-container:not(.editable) > .MuiGrid-item, .borrador .datos.cliente > .MuiGrid-container:not(.editable) > .MuiGrid-item {
  padding-top: var(--air-quad);
  padding-bottom: var(--air-quad);
}

.factura .datos.cliente > .MuiGrid-container:not(.editable) > .MuiGrid-item:first-child, .borrador .datos.cliente > .MuiGrid-container:not(.editable) > .MuiGrid-item:first-child {
  margin-top: var(--air-half);
}

.factura .datos.cliente > .MuiGrid-container:not(.editable) > .MuiGrid-item:last-child, .borrador .datos.cliente > .MuiGrid-container:not(.editable) > .MuiGrid-item:last-child {
  margin-bottom: var(--air-half);
}

.factura .datos.cliente > .MuiGrid-container.editable > .MuiGrid-item, .borrador .datos.cliente > .MuiGrid-container.editable > .MuiGrid-item {
  padding-top: 0;
  padding-bottom: 0;
}

.factura .datos.cliente .widgetContacto .MuiInput-input, .borrador .datos.cliente .widgetContacto .MuiInput-input {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.factura .datos.cliente:before, .borrador .datos.cliente:before {
  box-sizing: border-box;
  width: 100%;
}

.factura .datos.cliente .MuiIconButton-root, .borrador .datos.cliente .MuiIconButton-root {
  padding: 0;
}

.factura .datos.cliente table td, .borrador .datos.cliente table td {
  text-align: left;
}

.factura section, .borrador section {
  margin: calc(var(--air) * 2) 0;
}

.factura .cajetin, .borrador .cajetin {
  max-width: calc(var(--ancho-importe) * 3);
}

.factura .cajetinTotal, .borrador .cajetinTotal {
  width: calc(var(--ancho-importe) * 3);
}

.factura section.operaciones, .borrador section.operaciones {
  scrollbar-width: thin;
  max-width: 100%;
  overflow-x: auto;
}

#root[aria-hidden="true"] .factura section.operaciones, #root[aria-hidden="true"] .borrador section.operaciones {
  overflow-x: hidden;
}

.factura section.operaciones thead th, .borrador section.operaciones thead th {
  border: var(--bordr);
}

.factura section.operaciones table tbody :first-child > td, .borrador section.operaciones table tbody :first-child > td {
  padding-top: 1rem;
}

.factura section.operaciones table tbody td, .borrador section.operaciones table tbody td {
  border: none;
}

.factura section.operaciones table tr > :not(:nth-child(2)), .borrador section.operaciones table tr > :not(:nth-child(2)) {
  white-space: nowrap;
  width: min-content;
}

.factura section.operaciones table tr > :not(:nth-child(2)) input, .borrador section.operaciones table tr > :not(:nth-child(2)) input {
  text-align: right;
}

.factura section.operaciones table tr > :first-child, .borrador section.operaciones table tr > :first-child {
  min-width: 7em;
  max-width: 7em;
}

.factura section.operaciones table tr > :first-child input, .borrador section.operaciones table tr > :first-child input {
  text-align: left;
}

.factura section.operaciones table tr > :nth-child(2), .borrador section.operaciones table tr > :nth-child(2) {
  min-width: 18em;
  max-width: 18em;
}

.factura section.operaciones table tr > :nth-child(2) ~ :not(th), .borrador section.operaciones table tr > :nth-child(2) ~ :not(th) {
  text-align: right;
}

.factura section.operaciones table tr > :nth-child(2) ~ :not(th) input, .borrador section.operaciones table tr > :nth-child(2) ~ :not(th) input {
  min-width: 2em;
}

.factura section.operaciones table tr > :nth-child(3), .borrador section.operaciones table tr > :nth-child(3) {
  width: 1.5em;
  min-width: 1.5em;
}

.factura section.operaciones table tr > :nth-child(4), .borrador section.operaciones table tr > :nth-child(4) {
  max-width: var(--ancho-importe);
  min-width: var(--ancho-importe);
}

.factura section.operaciones table tr > :nth-child(5), .borrador section.operaciones table tr > :nth-child(5), .factura section.operaciones table tr > :nth-child(6), .borrador section.operaciones table tr > :nth-child(6) {
  max-width: var(--ancho-importe);
  width: var(--ancho-importe);
}

.factura section.operaciones table tr > :nth-child(2) > *, .borrador section.operaciones table tr > :nth-child(2) > * {
  text-align: left;
  display: block;
}

.factura section.operaciones table tr > :nth-child(2) strong, .borrador section.operaciones table tr > :nth-child(2) strong {
  font-size: 1.01em;
  font-weight: normal;
}

.factura section.operaciones table tr > :nth-child(2) textarea, .borrador section.operaciones table tr > :nth-child(2) textarea {
  font-size: .82em;
}

.factura section.operaciones table tr > :nth-child(2) small, .borrador section.operaciones table tr > :nth-child(2) small {
  text-rendering: optimizelegibility;
  margin-top: var(--air-half);
  letter-spacing: .120064px;
  overflow-wrap: break-word;
  font-size: .88em;
  line-height: 1.5;
}

.factura section.operaciones table tbody td, .borrador section.operaciones table tbody td {
  vertical-align: top;
}

.factura section.operaciones table tbody, .borrador section.operaciones table tbody {
  --base: 12;
  --less: 0;
}

.factura section.operaciones table tbody tr:last-child, .borrador section.operaciones table tbody tr:last-child {
  height: calc(var(--air-more) * (var(--base)  - var(--less)));
}

.factura section.operaciones table tbody tr + tr:last-child, .borrador section.operaciones table tbody tr + tr:last-child {
  --less: 2;
}

.factura section.operaciones table tbody tr + tr + tr:last-child, .borrador section.operaciones table tbody tr + tr + tr:last-child {
  --less: 4;
}

.factura section.operaciones table tbody tr + tr + tr + tr:last-child, .borrador section.operaciones table tbody tr + tr + tr + tr:last-child {
  --less: 6;
}

.factura section.operaciones table tbody tr + tr + tr + tr + tr:last-child, .borrador section.operaciones table tbody tr + tr + tr + tr + tr:last-child {
  --less: 8;
}

.factura section.operaciones table tbody tr + tr + tr + tr + tr + tr:last-child, .borrador section.operaciones table tbody tr + tr + tr + tr + tr + tr:last-child {
  --less: 10;
}

.factura section.operaciones table tbody tr + tr + tr + tr + tr + tr + tr:last-child, .borrador section.operaciones table tbody tr + tr + tr + tr + tr + tr + tr:last-child {
  --less: var(--base);
}

.factura section.operaciones menu.actividades, .borrador section.operaciones menu.actividades {
  margin: var(--air) auto;
  width: min-content;
  padding: 0;
}

.factura section.operaciones table tfoot td, .borrador section.operaciones table tfoot td {
  font-size: .95em;
  font-style: italic;
}

.factura section.operaciones input[type="number"], .borrador section.operaciones input[type="number"] {
  -moz-appearance: textfield;
}

.factura section.operaciones input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.factura section.operaciones input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.borrador section.operaciones input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.borrador section.operaciones input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.factura section.totales, .borrador section.totales {
  scrollbar-width: thin;
  justify-content: flex-end;
  max-width: 100%;
  display: flex;
  overflow-x: auto;
}

#root[aria-hidden="true"] .factura section.totales, #root[aria-hidden="true"] .borrador section.totales {
  overflow-x: hidden;
}

.factura section.totales tr, .factura section.totales td:last-child, .borrador section.totales tr, .borrador section.totales td:last-child {
  border: var(--bordr);
}

.factura section.totales tr:not(:first-child):not(:last-child) > :nth-child(2), .borrador section.totales tr:not(:first-child):not(:last-child) > :nth-child(2) {
  min-width: 8em;
  max-width: 8em;
}

.factura section.totales tr:not(:first-child):not(:last-child) > :nth-child(2) input, .borrador section.totales tr:not(:first-child):not(:last-child) > :nth-child(2) input {
  text-align: right;
}

.factura section.totales tr, .borrador section.totales tr {
  min-width: var(--ancho-importe);
}

.factura section.totales tr > td, .borrador section.totales tr > td {
  text-align: right;
  width: var(--ancho-importe);
  max-width: var(--ancho-importe);
}

.factura section.totales tr > th, .borrador section.totales tr > th {
  text-align: left;
}

.factura section.totales tr:last-of-type > *, .borrador section.totales tr:last-of-type > * {
  font-weight: 500;
}

.factura section.datos.pago > .MuiGrid-container, .borrador section.datos.pago > .MuiGrid-container {
  border: var(--bordr);
  padding: 0 .5rem;
  font-size: .9em;
}

.factura section.datos.pago > .MuiGrid-container .MuiGrid-item, .borrador section.datos.pago > .MuiGrid-container .MuiGrid-item {
  margin: calc(var(--air-quad) / 2) 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.factura section.datos.pago > .MuiGrid-container .MuiGrid-item:first-child, .borrador section.datos.pago > .MuiGrid-container .MuiGrid-item:first-child {
  font-weight: lighter;
}

.factura section.datos.pago > .MuiGrid-container .MuiGrid-item input.MuiAutocomplete-input, .borrador section.datos.pago > .MuiGrid-container .MuiGrid-item input.MuiAutocomplete-input {
  padding-bottom: 3px !important;
}

.factura section.datos.pago .vence:after, .borrador section.datos.pago .vence:after {
  border-bottom: none;
}

.factura section.datos.pago .vence input, .borrador section.datos.pago .vence input {
  text-align: left;
}

.factura section.datos.pago .metodo-cobro .MuiInput-root:after, .borrador section.datos.pago .metodo-cobro .MuiInput-root:after {
  border-bottom: none;
}

.factura footer, .borrador footer {
  font-weight: lighter;
}

.factura footer address, .borrador footer address {
  text-align: center;
  font-size: .8em;
}

.card.todo section.operaciones table tbody tr td:nth-child(6) {
  line-height: 1.8;
}

.dialog-emitir .MuiDialogActions-root {
  justify-content: space-between;
  display: flex;
}

@media (width >= 768px) {
  .factura section.operaciones table tr > :first-child, .borrador section.operaciones table tr > :first-child {
    min-width: 4em;
    max-width: 5em;
  }

  .factura section.operaciones table tr > :nth-child(4), .borrador section.operaciones table tr > :nth-child(4) {
    max-width: var(--ancho-importe);
    width: var(--ancho-importe);
  }

  .factura section.datos.pago > .MuiGrid-container .MuiGrid-item, .borrador section.datos.pago > .MuiGrid-container .MuiGrid-item {
    margin: calc(var(--air-quad) / 2) 0;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .factura section.datos.pago .vence input, .borrador section.datos.pago .vence input {
    text-align: center;
  }
}

.uploadGroup {
  align-items: center;
  display: flex;
}

.uploadGroup .MuiTypography-root {
  font-size: .8rem;
}

.uploadGroup .img-title {
  padding-bottom: .5rem;
}

.uploadGroup .logo, .uploadGroup .logo .img-group {
  display: flex;
}

.uploadGroup .logo .img-text {
  align-self: flex-end;
  font-style: italic;
}

.empresaFormRoot {
  box-sizing: border-box;
  padding: var(--air);
  margin-top: var(--air);
  width: 100%;
  max-width: 850px;
  font-size: .8rem;
}

.empresaFormRoot input, .empresaFormRoot label, .empresaFormRoot textarea {
  text-align: left;
  font-size: .8rem;
}

.empresaFormRoot .MuiAccordionSummary-root {
  margin: 0;
  min-height: 48px !important;
}

.empresaFormRoot .MuiAccordionSummary-root .accordionControl {
  flex-flow: row;
  display: flex;
}

.empresaFormRoot .MuiAccordionSummary-root .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: .9rem;
}

.empresaFormRoot .MuiAccordionSummary-root .MuiAccordionSummary-content button {
  justify-content: flex-end;
  padding: 0;
}

.empresaFormRoot .MuiAccordionSummary-root .MuiAccordionSummary-content button span {
  margin: 0;
}

.empresaFormRoot .MuiAccordionSummary-root .MuiAccordionSummary-content button span .MuiCollapse-wrapperInner {
  color: #0000008a;
  display: flex;
}

.empresaFormRoot .MuiAccordionDetails-root {
  padding: 8px 1.5rem 16px;
}

.empresaFormRoot .MuiAccordionDetails-root div {
  font-size: .8rem;
}

.empresaFormRoot .listConceptos {
  overflow-y: overlay;
  max-height: 800px;
  padding: 0 16px;
}

.empresaFormRoot .listConceptos .valores {
  display: flex;
}

.empresaFormRoot .listConceptos .valores .MuiInputLabel-root {
  width: 100%;
}

.empresaFormRoot .listConceptos .valores.precio {
  justify-content: right;
}

.empresaFormRoot .listConceptos .valores.precio .MuiInput-input, .empresaFormRoot .listConceptos .valores.precio .MuiInputLabel-root {
  text-align: right;
  transform-origin: 100%;
}

.empresaFormRoot .listConceptos .concepto-buttonWrapper {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.empresaFormRoot .listConceptos .concepto-buttonWrapper .concepto-icons .MuiCollapse-wrapperInner {
  flex-wrap: nowrap;
  display: flex;
}

.empresaFormRoot .listConceptos .concepto-buttonWrapper .concepto-icons .MuiCollapse-wrapperInner .MuiButtonBase-root {
  margin-left: 10px;
  padding: 0;
}

@media (width >= 768px) {
  .empresaFormRoot .listConceptos .valores {
    justify-content: right;
  }

  .empresaFormRoot .listConceptos .valores .MuiInput-input, .empresaFormRoot .listConceptos .valores .MuiInputLabel-root {
    text-align: right;
    transform-origin: 100%;
  }
}

.card-menu {
  justify-content: space-evenly;
  margin: 0 0 2rem;
  padding: 0;
  display: flex;
}

.card-menu .MuiIconButton-root svg {
  width: .82em;
  height: .82em;
}

article.card.concepto {
  box-shadow: none;
  cursor: auto;
}

article.card.concepto .readOnly {
  z-index: 0;
}

article.card.concepto .MuiFormControl-root, article.card.concepto .MuiButtonBase-root {
  z-index: 3;
}

#root > main {
  flex-flow: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 5em;
  display: flex;
}

#root > main > section.provisional {
  text-align: center;
}

#root > .notifications-component {
  top: 0;
}

#root > footer {
  z-index: 10;
  background-color: #fafafaef;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
}

.workDays > div {
  padding-top: 1px;
}

.login {
  justify-content: center;
  margin: auto;
  display: flex;
}

.login .login-container {
  align-items: center;
  max-width: 336px;
  display: flex;
  flex-direction: column !important;
}

.login .login-container .MuiAvatar-root {
  width: 65px;
  height: 65px;
}

.settings-container .quick-settings {
  max-width: 850px;
}

.settings-container .quick-settings > button {
  color: #000;
  text-transform: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
}

.settings-container .quick-settings > button .MuiCollapse-wrapperInner {
  display: flex;
}

.settings-container .settings * {
  margin-left: 0;
  font-size: .8rem;
}

.settings-container .settings .MuiCollapse-wrapperInner > .MuiGrid-root {
  width: 95%;
}

.settings-container .settings-inner {
  justify-content: center;
}

.settings-container .settings-inner .accordionControl {
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  display: flex;
}

.settings-container .settings-inner .accordionControl button {
  text-transform: none;
  color: #000;
  margin-left: 10px;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
}

.settings-container > .MuiPaper-root {
  color: #000;
  z-index: 10;
  overflow-y: auto;
  overflow-y: overlay;
  background-color: #fff;
  width: 100%;
  min-width: 340px;
  max-width: 100vw;
  font-size: .8rem;
  box-shadow: 4px 0 10px -1px #0003;
}

.settings-container .quick-filters .chip-filter {
  justify-content: center;
  margin-top: 20px;
}

@media (width >= 768px) {
  .settings-container > .MuiPaper-root {
    width: 25vw;
    max-width: 450px;
  }

  .quick-settings > button .MuiButton-endIcon {
    margin-left: 2rem;
  }
}

.slide-popper > .MuiPaper-root {
  z-index: 2000;
  background-color: #fff;
  border-radius: 4px;
  width: 300px;
  padding: 30px 20px 10px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.slide-popper > .MuiPaper-root span {
  font-size: .8rem;
}

.MuiDrawer-root nav.drawer-menu {
  padding: var(--air);
  flex-flow: column wrap;
  display: flex;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #64b5f680;
  border: 0 #f3e7e7;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #64b5f6cc;
}

::-webkit-scrollbar-thumb:active {
  background: #64b5f6cc;
}

::-webkit-scrollbar-track {
  background: #bebebe33;
  border: 0 #fff;
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background: none;
}

@media (width >= 768px) {
  #root > main {
    padding-bottom: 8em;
  }

  ::-webkit-scrollbar-track {
    background: #bebebe80;
    border: 0 #fff;
    border-radius: 6px;
  }
}

body {
  background-color: var(--body-bg);
  margin: 0;
}

html {
  scrollbar-width: thin;
  overflow-y: overlay;
}

#root {
  font-family: var(--main-font);
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

:root {
  --main-font: "Roboto", "Helvetica", "Arial", sans-serif;
  --marea-red: #b84232;
  --marea-dark: #00587a;
  --marea-light: #7fa1bd;
  --air: .5rem;
  --air-full: var(--air);
  --air-half: calc(var(--air) / 2);
  --air-quad: calc(var(--air) / 4);
  --air-hack: calc(var(--air) * -1);
  --air-more: calc(var(--air) * 1.5);
  --body-bg: #fafafa;
  --main: #2196f3;
  --main-dark: #1769aa;
  --main-light: #4dabf5;
  --alt1: #f50057;
  --alt1-dark: #ab003c;
  --alt1-light: #f73378;
  --fail: #f44336;
  --fail-dark: #d32f2f;
  --fail-light: #e57373;
  --warn: #ff9800;
  --warn-dark: #f57c00;
  --warn-light: #ffb74d;
  --info: #2196f3;
  --info-dark: #1976d2;
  --info-light: #64b5f6;
  --done: #4caf50;
  --done-dark: #388e3c;
  --done-light: #81c784;
}
/*# sourceMappingURL=index.1346924e.css.map */
