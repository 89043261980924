.newCard {
  position: sticky;
  top: 110px;
  width: 98%;
  max-width: 850px;
  padding: var(--air) var(--air) 0 var(--air);
  z-index: 3;
  box-shadow: none;
  cursor: pointer;
  .newCardContent {
    color: rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    padding: var(--air);
    span {
      margin-left: 10px;
    }
  }
}

section.card-list {
  box-sizing: border-box;
  padding: var(--air);
  margin-top:var(--air);
  width: 100%;
  // max-width: 850px;
}

.group-separator-container {
  width: 100%;
  justify-content: center;
  .group-separator {
    display: flex;
    padding-top: 1rem;
    width: 850px;
    font-size: 0.8rem;
  }
}

.WidgetContacto,
.WidgetConcepto,
.fecha,
.descripcion {
  .MuiInputBase-root input {
    padding-top: 3px;
  }
  label.MuiInputLabel-root[data-shrink=false] {
    transform: translate(0, 21px) scale(1);
  }
}

.fecha, .WidgetContacto {
  .MuiInputBase-root input {
    font-weight: 500;
  }
}

.dialog-contacto-container{
  .dialog-title {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    .MuiAvatar-root {
      margin-right: 1rem;
    }
  }
  .DialogContacto {
    min-width: 320px;
    padding: 10px 10px !important;
    .MuiTypography-root {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .MuiDivider-root{
    margin-bottom: 1rem;
  }

  .MuiList-root {
    margin-top: 0 !important;
  }

  .contact-group {
    justify-content: space-evenly;
    padding-bottom: 16px;
  }
}
}


.Ayuntamiento {
  div {
    font-size: 0.8rem !important;
  }
}

// por standard, las tarjetas 1dp sin ser seleccionadas y 8dp seleccionadas

.card:hover {
  box-shadow: 0px 2px 4px -1px; // 4dp
}

.createCard.selected {
  box-shadow: 0px 5px 5px -3px; // 8dp
}

.card {
  box-shadow: 0px 2px 1px -1px; // 1dp
  grid-column: 2;
  & menu.card-menu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .MuiIconButton-root svg {
      width: .82em;
      height: .82em;
    }

    .info-contacto {
      justify-content: flex-start;
      font-size: .7rem;
      text-transform: none;
      font-weight: 400;
      max-width: 100%;
      display: inline-flex;
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      cursor: pointer;
      text-align: start;
      line-height: normal;
      z-index: 2;

      span {
        padding-right: 5px;
      }
    
      &:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }

  &  form {
    margin-top: var(--air-half);
  }

  &  form .Acordeon:last-child {
    margin-bottom: 0;
  }

  &  form .Acordeon legend .MuiButton-root,
  .MuiInputBase-root,
  .MuiFormLabel-root,
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root fieldset legend,
  .MuiInputAdornment-root .MuiTypography-root {
    font-size: .8rem;
  }

  .MuiFormControl-root .MuiInputBase-root {
    &.MuiInput-underline {
      &:not(:hover) {
        &::before { content: unset; }
      }
      input, .MuiSelect-root { padding-bottom: 4px; }
      .MuiSelect-root { padding-left: 4px; }
    }
  }

  &  form .MuiInputBase-inputMultiline {
    resize: vertical;
  }

  .tabContent {
    
    margin: 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    /*align-content: space-between;*/
    column-gap: 5%;
    justify-content: space-between;
    
    .MuiGrid-item {
      
      padding: 0;

      .MuiFormControl-root {
        width: 70%;
      }
    }
  }  
}

/**
 * Estilos del componente tarjeta cuando se emplea el ItemHOC
 */
article.card, article.card + div, .MuiDialogContent-root.card.actividad.selected {
  box-sizing: border-box;
  margin: var(--air) auto;
  padding: var(--air);
  // border: 1px solid transparent;
  // box-shadow: 0 0 1px 0 gray;
  width: 100%;
  max-width: 850px;
  // border-radius: .5em;

  &:focus { outline: 0; }

  position: relative;

  & .empresa-activa {
    position: absolute;
    right: var(--air-half);
    top: calc(var(--air-more)*-1);
  }

  & .MuiAvatarGroup-root {
    flex-direction: column;
    align-items: flex-end;
    right: var(--air-half);
    top: calc(-1 * var(--air-more));

    button {
      width: 35px;
      padding: 0;
      justify-content: flex-end;
    }
  }

  .multiple {
    flex-direction: row;
    justify-content: right;
    :not(.MuiSvgIcon-root){
      margin-left: -25px !important;
    }
  }

  .sm-avatar {
    width: 30px;
    height: 30px;
    z-index: 1;
    // transition: all ease-in .1s;
    --bg: var(--dark, gray);
    background-color: var(--bg);

    &.estado.abierta { --bg: var(--info, #2196f3); }
    &.estado.cerrada { --bg: var(--done, orange); }
    &.estado.anulada { --bg: var(--fail, orange); }
    &.estado.atrasada { --bg: var(--warn)}
    &.estado.oculta { display: none; }
    &.comercial.pendiente { --bg: var(--dark, gray); }
    &.comercial.emitida { --bg: var(--warn, orange); }
    &.comercial.facturada { --bg: var(--done, green); }
    &.comercial.atrasada { --bg: var(--fail, red); }
  }

  /*&:hover > .MuiAvatarGroup-root > .MuiAvatar-root {
    color: var(--dark, gray);
    background-color: var(--light, white);
  }*/

  // Pantalla mediante overlay
  &:not(.selected):after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    /* outline: 2px solid pink; */
  }
  &:not(.selected):hover:after {
    background-color: transparent;
    transition: all .5s ease;
  }

  /*&.selected {
    // border-color: var(--alt1, firebrick);

  }*/

  &:not(.selected),
  &:focus:not(.selected) {
    cursor: pointer;
    // outline: 0;
    // border-color: var(--main, green);
    // box-shadow: 0 0 0 1px green;
  }
}

@media (min-width: 768px) {
  .newCard {
    padding: 0;
  }
}

/*
Esta regla está creando inconsistencias
article.card > form .MuiFormControl-root {
  margin: var(--air-half) 0;
}
*/

/**
 * TODO: Esto es específico de factura
 */
article.card.todo .MuiGrid-container.actividad-comercial {
  margin-top: calc(var(--air-more) * 4);
  margin-left: -4px;
}
article.card.todo menu.actividades {
  text-align: right;
}


