section.agenda {
  box-sizing: border-box;
  padding: var(--air);
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  min-height: 80vh;
  max-height: 85vh;
  // touch-action: none;
  --bg: white;

  // pruebas**************************

  .react-swipeable-view-container {
    height: 100%;
  }
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .loadingBackground {
    position: absolute;
    z-index: 5;
   
  }
  .loading {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);

    span {
      position: absolute;
      left: 50%;
      bottom: 50%;
    }
  }
  //*****************************************

  .fc {
    width: 100%;
    height: 100%;

    table {
      font-size: 0.7rem;
      
      th.fc-timegrid-axis {
        border: none;
      }
      colgroup col {
        width: 34px !important;
      }
      
      .fc-timegrid-slots table{
        margin-top: 10px;
      }
    }

    .fc-highlight {
      z-index: 0;
      background: rgba(0, 0, 0, 0);
    }
    
    .fc-scrollgrid-liquid {
      // max-height: 80%;
      // margin-top: 1%; 
      // border-width: 1px 0px 0px 0px;
      box-shadow: 
        0px 2px 1px -1px rgba(0,0,0,0.2),
        0px 2px 1px 0px rgba(0,0,0,0.14);
        // 0px 2px 3px 0px rgba(0,0,0,0.12); 
      border-radius: 4px;
      border-left: none;
      border-top: none;
      border-right: none;

      th {
        border-right: none;
      }
      thead .fc-scroller {
        overflow: hidden !important;
      }

    }
    
    .fc-mes-view {
      .fc-scrollgrid-liquid {
        border-left: 1px solid var(--fc-border-color, #ddd);
      }
    }

    .fc-timegrid {
      overflow-y: hidden;
    }

    .fc-timegrid-axis-cushion {
      max-width: 33px;
      padding: 0 2px 0 0;
      text-align: right;
    }

    .fc-timegrid-slot-label-cushion {
      padding: 0 2px 0 0;
    }

    .fc-timegrid-slot {
      height: 3rem !important;
    }

    .fc-timegrid-event:hover, .fc-daygrid-event:hover {
      border-radius: 4px;
      box-shadow: 0px 2px 4px -1px; // 4dp
    }

    .fc-col-header-cell {
      font-weight: normal;
      text-transform: capitalize;
      border-left: none !important;
      border-right: none !important;
    }

    .fc-theme-standard td, .fc-theme-standard th {
      border: none !important;
    }

    .fc-day-today:not(.fc-col-header-cell){
      background-color: rgba(77, 171, 245, 0.15);
    }

    a.fc-event, a.fc-h-event {
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 
        0px 2px 1px -1px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14),
        0px 1px 3px 0px rgba(0,0,0,0.12);       
      background-color: #fff;
      border-radius: 4px;
      :hover { 
        box-shadow: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      .fc-event-main {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
      }
    }

    .fc-daygrid-day {
      height: 20%;
      max-height: 20%;
    }

    .fc-daygrid-day-events {
      margin-bottom: 2rem;
    }


    .eventContainer {
      width: 100%;
      color:rgba(0, 0, 0, 0.87);
      padding-left: 2px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .dot-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .status-dot {
          border-radius: 1em;
          margin-top: -6px;
          box-shadow: inset 1px 1px 1px 2px white;

          &.abierta { color: var(--info); }
          &.cerrada { color: var(--done); }
          &.anulada { color: var(--fail); }
          &.atrasada { color: var(--warn); }
          &.oculta {
            display: none;
          }
        }
      }

      .MuiAvatarGroup-root {
        width:100%;
        flex-direction: column-reverse;
        align-items: flex-end;

        & button {
          padding: 0;
        }
        
        & button > .MuiAvatar-root, &.multiple > .MuiAvatar-root {          
          --bg: var(--dark, gray);
          background-color: var(--bg);
          width: 1.1rem;
          height: 1.1rem;
    
          &.estado.abierta { --bg: var(--info, #2196f3); }
          &.estado.cerrada { --bg: var(--done, orange); }
          &.estado.anulada { --bg: var(--fail, orange); }
          &.estado.atrasada { --bg: var(--warn); }
          &.estado.oculta { display: none; }
          // &.comercial.pendiente { --bg: var(--warn, orange); }
          // &.comercial.facturada { --bg: var(--done, orange); }
        }
        &.multiple {
          display: flex;
          flex-direction: row;
          justify-content: right;
          :not(.MuiSvgIcon-root){
            margin-left: -15px !important;
          }
          .MuiAvatar-root {
            justify-content: space-around;
            width: 1.1rem;
            height: 1.1rem;
          }
        }
      }


      &:hover > .MuiAvatarGroup-root > .MuiAvatar-root {
        color: var(--dark, gray);
        background-color: var(--light, white);
      }
      .eventText {
        grid-column: 1;
        display: flex; 
        flex-direction: column; 
        justify-content: center; 
        text-align: start;        
      }
      .MuiSvgIcon-root { font-size: 0.8rem;}
    }

    .fc-mes-view, 
    .fc-semana-view, 
    .fc-tresDias-view {
      .fc-timegrid-slot {
        height: 2rem !important;
      }
  
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-label-frame {
        border: none;
        margin-top: -24px;
        box-shadow: none;
      }
      .eventContainer {
        .status-avatar-wrap {
          display: none;
        }
      }
    }

    .fc-tresDias-view {
      .eventContainer {
        font-size: 0.7rem;
      }
    }

    .fc-dia-view {
      .eventContainer {
        font-size: 0.8rem;
        .dot-wrap {
          display: none;
        }
      }
    }

    @media (min-width: 768px) {
      .fc-mes-view, 
      .fc-semana-view, 
      .fc-tresDias-view {
        .eventContainer {
          justify-content: left;
          .eventText {
            margin-left: 5px;
          }
          .dot-wrap {
            margin-left: 2px;
          }  
        }
        .fc-timegrid-slot-label,
        .fc-timegrid-slot-label-frame {
            margin-top: -27px;
        }
      }
     }
  }

  .sideArrow {
    z-index: 5;
    border-radius: 0;
    padding: 0;
    // Necesitamos mostrar las flechas hasta que tengamos la
    // posibilidad de reincorporar el slide
    display: inline-flex;
    // display: none;
  }

  @media (min-width: 768px) {
    .sideArrow {
      display: inline-flex
    }
  }
}

.actividadAgenda {
  margin: 0 !important;
}