.quick-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  .MuiMonthPicker-root, .MuiCalendarPicker-root, .MuiYearPicker-root {
    height: 358px;
  }

  .MuiTabPanel-root {
    padding: 0;
    min-height: 340px;
    > div {
      .PrivatePickersYear-root {
        flex-basis: 10%;
      }
    }
  }

  .dias {
    background-color: transparent;
    &:hover, &:focus {
      background-color: rgb(239, 239, 239);
    }
  }

  .selected {
    background-color: rgba(66, 165, 245, 0.6);
    > .dias{ 
      color: white; 
      &:hover, &:focus {
        background-color: transparent;
        border: 0.2px solid rgba(0, 0, 0, 0.4);
      }
    }        
    &.dia-central {
      // background-color: rgba(66, 165, 245, 0.6);
      border-radius: 0;
      > .dias { 
        color: rgba(0, 0, 0, 0.87);
      }
    }
    &.dia-izquierdo {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    &.dia-izquierdo > .dias{
      border-radius: 50%;
      background-color: #1976d2;
      // border-bottom-left-radius: 50%;
    }
    &.dia-derecho {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    &.dia-derecho > .dias{
      border-radius: 50%;
      background-color: #1976d2;
      // border-bottom-left-radius: 50%;
    }
    &.dia-unico {
      border-radius: 50%;
      background-color: #1976d2;
    }
  }

  .PrivatePickersSlideTransition-root .css-mvmu1r {
    :last-child {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    :first-child {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }
  
}