#root > header.MuiAppBar-root {
  background-color: #fafafaef;

  .MuiBottomNavigation-root {
    background-color: var(--main-dark);

    .MuiBottomNavigationAction-root {
      color: white;
      &.Mui-selected {
        color: var(--alt1-light);
        .MuiBottomNavigationAction-label {
          color: white;
          max-height: 15px;
          overflow: hidden;
        }
      }
      .MuiBottomNavigationAction-label {
        max-height: 15px;
        overflow: hidden;
      }
    }
  }
  .logo {
    max-width: 24px;
    max-height: 24px;
  }
  .MuiToolbar-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin: auto;
    box-sizing: border-box;
    width: 100%;
    max-height: 90vh;

    padding: 0 var(--air);

    // margin-top: var(--air);
    // background-color: #fafafaef;
    // max-width: 850px;
    // margin: 0 auto;

    .MuiInputAdornment-root {
      .MuiIconButton-root {
        padding: 6px;
        color: var(--marea-dark);
      }
    }

    > .MuiChip-root {
      margin-right: 12px;
    }

    .MuiChip-outlined {
      border: none;
      width: 100%;
      justify-content: flex-start;
    }

    .MuiInputBase-root {
      // color: white;

      &::before {
        // border-color: white;
        border-bottom-width: 1px;
      }
      &:not(:focus):not(:hover)::before { content: unset; }

      .MuiInputBase-input {
        padding-bottom: 3px;
      }

    }

    .searchPopper {
      z-index: 10 !important;
      > .options-container {
        padding: var(--air);
        overflow-y: auto;
        max-height: 80vh;
        display: flex;
        flex-direction: column;

        > *:not(:first-child) {
          margin-top: var(--air);
        }
      }
    }
  }

  .searchBar {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 550px;
    
    .MuiInputBase-inputTypeSearch {
      margin-left: 1rem;
    }
  }

  .chipList {
    display: flex;
    align-items: center;
    .MuiChip-label {
      &::first-letter{
        text-transform: uppercase;
      }
    }
  }
}