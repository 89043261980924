/*
 * Estilos de Factura
 */

/* @media screen and (min-width: 600px) {
  .card.factura.selected {
    // background-color: green;
    // padding: calc(var(--air) * 2);
  }
} */

.bgText {
  z-index: 0;
  font-size: 3.5rem;
  text-align: center;
  position: absolute;
  margin: 0;
  color: rgba(253, 70, 20, 0.1);
  transform: rotate(320deg);
  // bottom: auto;
  top: 8rem;
  right: 2rem;
}

.factura, .borrador {
  box-sizing: border-box;
  margin: 0;
  width: 100%;

  // font-size: .8rem;

  --ancho-importe: 7rem;
  --bordr: 1px solid var(--text-weak, lightgray);
  --bg: var(--dark, gray);

  .factura-wrap {
    align-self: 'center';
  }
  .facturaClosed .MuiCollapse-wrapperInner {
    display: flex; //FALLO DE RENDIMIENTO JUSTO AQUÍ
    flex-wrap: wrap;
    font-size: 0.8rem;
    .datos-closed {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .label {
        color: rgba(0, 0, 0, 0.6);
        margin: 0.5rem 0;
        font-weight: 400;
        font-size: 0.6rem;
      }

      .valor {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
   
  }

  .eurContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .euroButton {
      padding:0;
      min-width: fit-content;
      cursor: default;
      border-radius: 16px;
      font-size: .7rem;
      text-transform: none;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      .MuiAvatar-root {
        font-size: 1.25rem;
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }
    }
  }

  .menuContainer {
    .MuiCollapse-wrapperInner {
      display: flex;
      align-items: center;
    }
  }

  .MuiAvatar-root {
    
    background-color: var(--bg);
    
    &.estado.abierta { --bg: var(--info, #2196f3)}
    &.estado.cerrada { --bg: var(--done, orange); }
    &.estado.anulada { --bg: var(--fail, orange); }
    &.comercial.pendiente { display: none; }
    &.comercial.emitida { --bg: var(--warn, orange); }
    &.comercial.facturada { --bg: var(--done, green); }
    &.comercial.atrasada { --bg: var(--fail, red);}
  }

  /* .facturaAvatar {
    align-self: center;
  } */

  table {
    border-collapse: collapse;
    box-sizing: border-box;
    border: var(--bordr);
    width: 100%;

    font-size: .8rem;

    tr > * {
      text-align: center;
      padding: var(--air-half) var(--air-full);
    }

    th {
      box-sizing: border-box;
      // border: var(--bordr);
      font-weight: lighter;
    }

    td {
      font-weight: normal;
    }
  }

  .header {
    margin-top: var(--air);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    figure.logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;
      margin: 0;

      .imagotipo {
        min-width: 120px;
        max-width: 100%;
        // min-height: 120px;
        max-height: 220px;
      }
    }

    figure.datos.factura, figure.datos.borrador {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &:before {
        width: 100%;
        box-sizing: border-box;
      }
    }

    td {width: calc(var(--ancho-importe)*2)}

    figure.datos.empresa {
      // border: var(--bordr);
      font-size: .7rem;
      .MuiGrid-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .datos[data-title]:before {
    content: attr(data-title);
    text-transform: uppercase;
    font-weight: lighter;
    font-size: .9em;
    display: block;
    // border-bottom: 1px dotted;
    margin-bottom: var(--air-half);
    padding-left: calc(var(--air) + 1px);
  }

  .datos.factura, .datos.borrador {
    th, td { 
      border: var(--bordr); 
      text-align: left;
    }

    td small { font-style: italic; color: var(--disabled, lightgray); }
  }

  .datos.cliente {
    margin: 0;
    margin-top: 1rem;
    > .MuiGrid-container {
      padding: 0 var(--air-quad);
      border: var(--bordr);
      font-size: .8rem;

      &:not(.editable) > .MuiGrid-item {
        padding-top: var(--air-quad);
        padding-bottom: var(--air-quad);
        &:first-child { margin-top: var(--air-half); }
        &:last-child { margin-bottom: var(--air-half); }
      }
      &.editable > .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 0;
        
      }
    }

    .widgetContacto .MuiInput-input {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &:before {
      width: 100%;
      box-sizing: border-box;
    }

    .MuiIconButton-root {
      padding: 0;
    }

    table {
      td {
        text-align: left;
      }
    }
  }

  section {
    margin: calc(var(--air) * 2) 0;
  }

  .cajetin {
    max-width: calc(var(--ancho-importe) * 3);
  }
  
  .cajetinTotal {
    width: calc(var(--ancho-importe) * 3);
  }

  section.operaciones {
    max-width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;

    #root[aria-hidden="true"] & {
      overflow-x: hidden;
    }

    thead th {
      // overflow: hidden;
      // text-overflow: ellipsis;
      border: var(--bordr);
    }

    table tbody :nth-child(1) > td {
      padding-top: 1rem;
    }
    
    table tbody td {
      border: none;
    }

    table tr > * {
      &:not(:nth-child(2)) {
        width: min-content;
        white-space: nowrap;

        input { text-align: right; }
      }
      // Fecha
      &:nth-child(1) {
        input { text-align: left; }
        min-width:  7em;
        max-width:  7em;
        // input { min-width: 4em; }
      }
      // Concepto
      &:nth-child(2) {
        min-width: 18em;
        max-width: 18em;
      }
      &:nth-child(2) ~ :not(th) {
        text-align: right;
        input { min-width: 2em; }
      }
      // Uds
      &:nth-child(3) {
        min-width: 1.5em; 
        width: 1.5em;
      }
      // Precio
      &:nth-child(4) { 
        max-width: var(--ancho-importe);
        min-width: var(--ancho-importe);
      }
      // Descuento
      &:nth-child(5) { 
        max-width: var(--ancho-importe); 
        width: var(--ancho-importe);
      }
      // Importe
      &:nth-child(6) {
        max-width: var(--ancho-importe);
        width: var(--ancho-importe);
      }
    }

    table tr > :nth-child(2) {
      & > * {
        display: block;
        text-align: left;

        // &:first-child { margin-bottom: var(--air-quad); }
      }
      strong {
        font-weight: normal;
        font-size: 1.01em;
      }
      textarea { font-size: .82em; }
      small {
        font-size: .88em;
        text-rendering: optimizelegibility;
        margin-top: var(--air-half);
        line-height: 1.5;
        letter-spacing: 0.120064px;
        overflow-wrap: break-word;
      }
    }

    table tbody td { vertical-align: top; }

    table tbody {
      --base: 12;
      --less: 0;

      tr:last-child {
        height: calc(var(--air-more) * (var(--base) - var(--less)));
      }
      tr + tr:last-child { --less: 2; }
      tr + tr + tr:last-child { --less: 4; }
      tr + tr + tr + tr:last-child { --less: 6; }
      tr + tr + tr + tr + tr:last-child { --less: 8; }
      tr + tr + tr + tr + tr + tr:last-child { --less: 10; }
      tr + tr + tr + tr + tr + tr + tr:last-child { --less: var(--base); }
    }

    menu.actividades {
      margin: var(--air) auto;
      padding: 0;
      width: min-content;
    }

    table tfoot td {
      font-size: .95em;
      font-style: italic;
    }

    input[type=number] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  section.totales {
    display: flex;
    justify-content: flex-end;

    max-width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;

    #root[aria-hidden="true"] & {
      overflow-x: hidden;
    }

    tr, td:last-child { border: var(--bordr); }
    tr:not(:first-child):not(:last-child) > :nth-child(2) {
      min-width: 8em;
      max-width: 8em;
      input { text-align: right; }
    }
    tr { min-width: var(--ancho-importe); }
    tr > td { 
      text-align: right; 
      width: var(--ancho-importe); 
      max-width: var(--ancho-importe);
    }
    tr > th { text-align: left; }
    tr:last-of-type > * { font-weight: 500; }
  }

  section.datos.pago {
    & > .MuiGrid-container {
      border: var(--bordr);
      font-size: .9em;
      padding: 0 0.5rem;

      & .MuiGrid-item {
        margin: calc(var(--air-quad) / 2) 0;

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &:first-child {
            font-weight: lighter;
          }
          // border-right: var(--bordr);

          input.MuiAutocomplete-input { padding-bottom: 3px !important; }
        
      }
    }

    .vence {
      &::after {
        border-bottom: none;
      }
      input {
        text-align: left;
      }
    }

    .metodo-cobro .MuiInput-root::after{
      border-bottom: none;
    }
  }


  footer {
    font-weight: lighter;
    address {
      text-align: center;
      font-size: .8em;
    }
  }
}

.card.todo {
  section.operaciones table tbody tr td:nth-child(6) {
    line-height: 1.8;
    // background-color: green;
  }
}

.dialog-emitir {
  .MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
  }
}

@media(min-width: 768px) {
  .factura, .borrador {
    section.operaciones {
      table tr > * {
        // Fecha
        &:nth-child(1) {
          min-width:  4em;
          max-width:  5em;
        }
        // Precio
        &:nth-child(4) { 
          max-width: var(--ancho-importe);
          width: var(--ancho-importe);
        }
      }
    }

    section.datos.pago {
      & > .MuiGrid-container {

        & .MuiGrid-item {
          margin: calc(var(--air-quad) / 2) 0;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
      }
      
      .vence input{
        text-align: center;
      }
    }
  }
}
/* vim: set expandtab: */
/* vim: set filetype=scss ts=2 shiftwidth=2: */
