.WidgetContacto-dialog,
.WidgetConcepto-dialog {
  .MuiPaper-root {
    min-width: 320px;
    height: 650px;
  }
  .MuiDialogContent-root {
    min-width: fit-content;
    overflow-y: hidden;
    padding: 10px 10px;    
  }

  .MuiListItem-gutters {
    padding-left: 8px;
    padding-right: 8px;
  }

  .MuiList-root {
    min-height: 90%;
  }

  .listFooter {
    margin-top: 10px;
  }
}
