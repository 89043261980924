figure.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  // background-color: green;
  position: relative;
  text-align: center;

  .imagotipo {
    min-width: 120px;
    max-width: 100%;
    // min-height: 120px;
    max-height: 120px;
  }
}

.documento {
  padding: var(--air) + 1rem;
  margin: var(--air);

  .titulo{
    margin: var(--air)
  }

  figure.datos.cliente {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0;
    // border: 1px solid black;
  
    &:before {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .datos[data-title]:before {
    content: attr(data-title);
    text-transform: uppercase;
    font-weight: lighter;
    font-size: .9em;
    display: block;
    // border-bottom: 1px dotted;
    margin-bottom: var(--air-half);
    padding-left: calc(var(--air) + 1px);
  }
  
  .datos.cliente table {
      border: 0.5px solid rgb(201, 191, 191); 
      text-align: left; 
      max-width: 100%;
      width: 100%;
      margin-bottom: 1rem;
  
      td small { font-style: italic; color: var(--disabled, lightgray); }
  }

  p {
    text-align: justify;
    margin-bottom: 15px;
  }

  h6 {
    text-align: center;
  }

  .firma {
    margin-bottom: 6rem;
  }

  footer {
    font-weight: lighter;
    address {
      text-align: center;
      font-size: .8em;
    }
  }
}