.empresaFormRoot {
  box-sizing: border-box;
  padding: var(--air);
  margin-top:var(--air);
  width: 100%;
  max-width: 850px;
  font-size: 0.8rem;
  input, label, textarea {
    font-size: .8rem;
    text-align: left;
  }

  .MuiAccordionSummary-root {
    // padding: 0;
    margin: 0;
    min-height: 48px !important;
    .accordionControl {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
    }
    .MuiAccordionSummary-content {
      font-size: 0.9rem;
      margin: 0;
      justify-content: space-between;
      align-items: center;

      button {
        justify-content: flex-end;
        padding: 0;
        span {
          margin: 0;
          .MuiCollapse-wrapperInner {
            display: flex;
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
    }
  }
  
  .MuiAccordionDetails-root {
    padding: 8px 1.5rem 16px;
    div {
      font-size: 0.8rem;
    }
  }

  .listConceptos {
    max-height: 800px;
    padding: 0 16px;
    overflow-y: overlay;

    .valores {
      display: flex;
      .MuiInputLabel-root {
        width: 100%;
      }
      &.precio {
        justify-content: right;
        .MuiInput-input {
          text-align: right;
          transform-origin: right;
        }
        .MuiInputLabel-root {
          text-align: right;
          transform-origin: right;
        }
      }
    }
    .concepto-buttonWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .concepto-icons {
        .MuiCollapse-wrapperInner {
          display: flex;
          flex-wrap: nowrap;
          .MuiButtonBase-root {
            padding: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
  
  @media(min-width: 768px) {
    .listConceptos {
      .valores {
        justify-content: right;
        .MuiInput-input {
          text-align: right;
          transform-origin: right;
        }
        .MuiInputLabel-root {
          text-align: right;
          transform-origin: right;
        }
      }
    }
  }
}

.card-menu {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  margin-bottom: 2rem;
  padding: 0;
  .MuiIconButton-root svg {
    width: .82em;
    height: .82em;
  }
}

article.card.concepto {
  box-shadow: none;
  cursor: auto;

  .readOnly {
    z-index: 0;
  }
  .MuiFormControl-root, .MuiButtonBase-root{
    z-index: 3;
  }
}

